<template>
	<section>
		<base-section class="px-12" style="background: transparent">
			<base-section-heading :title="$t('dezhou2018')">
				August 2 - 17, 2018
			</base-section-heading>
			<v-row align="center" justify="center">
				<v-col cols="6"
					><iframe
						src="//player.bilibili.com/player.html?aid=754888063&bvid=BV1yk4y1C7aE&cid=244907802&page=1"
						scrolling="no"
						border="0"
						frameborder="no"
						framespacing="0"
						allowfullscreen="true"
						width="100%"
						height="450"
					>
					</iframe
				></v-col>

				<v-col cols="6">
					<p>
						Solar Decathlon China 2018 was organized by China
						Overseas Development Association and hosted in Dezhou,
						Shandong Province from August 2nd to 17th, with the
						authorization of the U.S. Department of Energy and
						guidance of the China National Energy Administration. The
						event also received support from the School Department
						of the Central Committee of the Communist Youth League
						and the Ministry of Housing and Urban-Rural Development
						of the People's Republic of China.
					</p>
					<p>
						The competition initially recruited 22 teams worldwide
						to participate. 19 teams consisting of 34 schools from 8
						countries/regions were qualified for the final,
						including 17 competition teams and 2 exhibition teams.
						Team SCUT-POLITO claimed the title in the final with a
						total score of 959.74. Team THU finished in second
						place, followed by Team JIA+ and Team TUBSEU that tied
						for third place. Team SCUT-POLITO, Team Montreal, and
						Team THU led the medal table.
					</p>
					<p>
						The host city Dezhou, known as the “Sun City of China”,
						is a prefecture-level city in northwestern Shandong
						province. It has become one of the largest solar
						industry clusters in China, covering massive of
						household utilities such as solar-powered water-heaters,
						solar photovoltaic power generation, solar and building
						integration, etc.
					</p>
				</v-col>
			</v-row>
		</base-section>

		<!-- Contests -->
		<base-section class="px-12" style="background: transparent">
			<base-section-heading :title="$t('contests')">
				Solar Decathlon China 2018 consists of 10 separately scored
				contests, and some contests contain one or more subcontests.
				Peruse Rules for details.
			</base-section-heading>

			<v-row>
				<v-col cols="12" class="d-flex justify-space-between">
					<v-card
						shaped
						style="width: 260px"
						v-for="(contest, i) in contests"
						:key="i"
						v-if="i < 5"
					>
						<v-card-text class="text-center">
							<v-avatar
								size="60"
								:color="contest.color + ' lighten-3'"
							>
								<v-icon large>{{ contest.icon }}</v-icon>
							</v-avatar>
							<h3 class="mt-3">{{ contest.title }}</h3>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12" class="d-flex justify-space-between">
					<v-card
						shaped
						style="width: 260px"
						v-for="(contest, i) in contests"
						:key="i"
						v-if="i > 4"
					>
						<v-card-text class="text-center">
							<v-avatar
								size="60"
								:color="contest.color + ' lighten-3'"
							>
								<v-icon large>{{ contest.icon }}</v-icon>
							</v-avatar>
							<h3 class="mt-3">{{ contest.title }}</h3>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</base-section>

		<!-- Finalist -->
		<base-section class="px-12" style="background: transparent">
			<base-section-heading :title="$t('finalist')">
				19 teams consisting of 34 schools from 8 countries/regions were
				qualified for the final, including 17 competition teams and 2
				exhibition teams.
			</base-section-heading>
			<v-row>
				<v-col cols="3" v-for="(team, i) in finalist" :key="i">
					<v-card outlined height="360px">
						<v-img
							class="white--text align-end"
							height="180px"
							:src="
								require(`@/assets/images/dezhou/renderings/${team.id}-${team.avatar}.png`)
							"
						>
							<v-card-title>{{ team.project }}</v-card-title>
						</v-img>

						<v-card-title class="d-flex justify-space-between">
							<v-avatar>
								<img
									:src="
										require(`@/assets/images/dezhou/logos/${team.id}-${team.avatar}-logo.png`)
									"
								/>
							</v-avatar>
							<v-badge
								left
								:content="team.id"
								:color="team.exh ? 'warning' : 'primary'"
							>
								{{ team.name }}</v-badge
							>
						</v-card-title>
						<v-card-text
							:style="
								team.scr
									? 'height: 98px; overflow-y: scroll'
									: ''
							"
						>
							<div class="d-flex justify-space-between">
								<span class="text-truncate">{{
									team.sch1
								}}</span>
								<span class="primary--text">{{
									team.con1
								}}</span>
							</div>
							<div class="d-flex justify-space-between">
								<span class="text-truncate">{{
									team.sch2
								}}</span>
								<span class="primary--text">{{
									team.con2
								}}</span>
							</div>
							<div class="d-flex justify-space-between">
								<span class="text-truncate">{{
									team.sch3
								}}</span>
								<span class="primary--text">{{
									team.con3
								}}</span>
							</div>
							<div class="d-flex justify-space-between">
								<span class="text-truncate">{{
									team.sch4
								}}</span>
								<span class="primary--text">{{
									team.con4
								}}</span>
							</div>
							<div class="d-flex justify-space-between">
								<span class="text-truncate">{{
									team.sch5
								}}</span>
								<span class="primary--text">{{
									team.con5
								}}</span>
							</div>
							<div class="d-flex justify-space-between">
								<span class="text-truncate">{{
									team.sch6
								}}</span>
								<span class="primary--text">{{
									team.con6
								}}</span>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</base-section>

		<!-- Jury -->
		<base-section class="px-12" style="background: transparent">
			<base-section-heading :title="$t('jury')">
				17 professionals from different realms of expertise were invited
				to judge Solar Decathlon China 2018, grouped by 5 juried
				contests.
			</base-section-heading>
			<v-row>
				<v-col
					cols="2"
					class="text-center"
					v-for="(jury, i) in juryPanel"
					:key="i"
				>
					<v-badge
						:content="jury.group"
						:color="jury.color"
						offset-x="60"
						offset-y="20"
					>
						<v-avatar size="180">
							<img
								:src="
									require(`@/assets/images/dezhou/jury/${jury.avatar}.png`)
								"
							/>
						</v-avatar>
					</v-badge>
					<h3 class="mt-3">{{ jury.name }}</h3>
					<div>
						<i>{{ jury.title }}</i>
					</div>
				</v-col>
			</v-row>
		</base-section>

		<!-- Results -->
		<base-section class="px-12" style="background: transparent">
			<base-section-heading :title="$t('results')">
				Team SCUT-POLITO claimed the title of Solar Decathlon China 2018
				with a total score of 959.74. Team THU finished in second place,
				followed by Team JIA+ and Team TUBSEU that tied for third place.
			</base-section-heading>
			<v-data-table
				:headers="headers"
				:items="scores"
				:items-per-page="17"
				sort-by="total"
				sort-desc="true"
				hide-default-footer
				class="elevation-0"
			></v-data-table>
		</base-section>

		<!-- Winners -->
		<base-section style="background: transparent">
			<base-section-heading :title="$t('winners')">
				Team SCUT-POLITO claimed the title of Solar Decathlon China
				2018. Team THU finished in second place, followed by Team JIA+
				and Team TUBSEU that tied for third place.
			</base-section-heading>

			<v-carousel cycle show-arrows-on-hover height="600">
				<v-carousel-item
					v-for="i in 10"
					:key="i"
					:src="
						require(`@/assets/images/dezhou/winners/sdc2018_${i}.png`)
					"
				></v-carousel-item>
			</v-carousel>
		</base-section>

		<!-- Acknowledgement -->
		<base-section class="px-12" style="background: transparent">
			<base-section-heading :title="$t('acknowledgement')">
			</base-section-heading>
		</base-section>
	</section>
</template>

<script>
export default {
	data: () => ({
		contests: [
			{
				title: "Architecture",
				icon: "mdi-home",
				color: "blue",
			},
			{
				title: "Market Appeal",
				icon: "mdi-currency-usd",
				color: "lime",
			},
			{
				title: "Engineering",
				icon: "mdi-code-tags",
				color: "cyan",
			},
			{
				title: "Communications",
				icon: "mdi-share-variant",
				color: "green",
			},
			{
				title: "Innovations",
				icon: "mdi-head-snowflake",
				color: "brown",
			},
			{
				title: "Comfort Zone",
				icon: "mdi-thermometer",
				color: "yellow",
			},
			{
				title: "Appliances",
				icon: "mdi-fridge",
				color: "teal",
			},
			{
				title: "Home Life",
				icon: "mdi-television-classic",
				color: "orange",
			},
			{
				title: "Commuting",
				icon: "mdi-car",
				color: "amber",
			},
			{
				title: "Energy",
				icon: "mdi-flash",
				color: "red",
			},
		],

		finalist: [
			{
				id: "01",
				project: "(S)LOW House",
				name: "B&R",
				avatar: "b&r",
				sch1: "The University of Hong Kong",
				con1: "HK",
				sch2: "Beijing University of Civil Engineering and Architecture",
				con2: "CN",
			},
			{
				id: "02",
				project: "The SHU House",
				name: "PKU",
				avatar: "pku",
				sch1: "Peking University",
				con1: "CN",
				exh: true,
			},
			{
				id: "03",
				project: "The WHAO House",
				name: "THU",
				avatar: "thu",
				sch1: "Tsinghua University",
				con1: "CN",
			},
			{
				id: "04",
				project: "Turtle Home",
				name: "XJTU-WNEU-POLIMI",
				avatar: "xjtu-wneu-polimi",
				sch1: "Xi'an Jiaotong University",
				con1: "CN",
				sch2: "Western New England University",
				con2: "US",
				sch3: "Politecnico di Milano",
				con3: "IT",
			},
			{
				id: "05",
				project: "The North Seal Residence",
				name: "YI",
				avatar: "yi",
				sch1: "Yantai University",
				con1: "CN",
			},
			{
				id: "06",
				project: "Lotus House",
				name: "WashU",
				avatar: "washu",
				sch1: "Washington University in St. Louis",
				con1: "US",
				exh: true,
			},
			{
				id: "07",
				project: "New Energy Smart House",
				name: "CNBM-WIN",
				avatar: "cnbm-win",
				sch1: "New Jersey Institute of Technology",
				con1: "US",
				sch2: "Wuhan University of Technology",
				con2: "CN",
			},
			{
				id: "08",
				project: "Dancing Sunlight",
				name: "SUES-XD",
				avatar: "sues-xd",
				sch1: "Shanghai University of Engineering Science",
				con1: "CN",
			},
			{
				id: "09",
				project: "Project Solarise",
				name: "Shunya",
				avatar: "shunya",
				sch1: "Indian Institute of Technology, Bombay",
				con1: "IN",
			},
			{
				id: "10",
				project: "Deep-Performance Dwelling",
				name: "Montreal",
				avatar: "montreal",
				sch1: "McGill University",
				con1: "CA",
				sch2: "Concordia University",
				con2: "CA",
			},
			{
				id: "11",
				project: "Nature • Between",
				name: "JIA+",
				avatar: "jia",
				sch1: "Xiamen University ",
				con1: "CN",
				sch2: "National School of Architecture of Britanny",
				con2: "FR",
				sch3: "High School Joliot Curie of Rennes",
				con3: "FR",
				sch4: "Technical School of Compagnons du Devoir of Rennes",
				con4: "FR",
				sch5: "National Institute of Applied Sciences of Rennes",
				con5: "FR",
				sch6: "Shandong University",
				con6: "CN",
				scr: true,
			},
			{
				id: "13",
				project: "C-House",
				name: "TUBSEU",
				avatar: "tubseu",
				sch1: "Southeast University",
				con1: "CN",
				sch2: "Technical University of Braunschweig",
				con2: "DE",
			},
			{
				id: "14",
				project: "Qiju 2.0",
				name: "XAUAT",
				avatar: "xauat",
				sch1: "Xi'an University of Architecture and Technology",
				con1: "CN",
			},
			{
				id: "15",
				project: "Waterside Cube",
				name: "SJTUIUC",
				avatar: "sjtuiuc",
				sch1: "Shanghai Jiao Tong University",
				con1: "CN",
				sch2: "University of Illinois at Urbana-Champaign",
				con2: "US",
			},
			{
				id: "16",
				project: "i-Yard 2.0",
				name: "BJTU",
				avatar: "bjtu",
				sch1: "Beijing Jiaotong University",
				con1: "CN",
			},
			{
				id: "17",
				project: "Es-Block",
				name: "SIE",
				avatar: "sie",
				sch1: "Shenyang Institute of Engineering",
				con1: "CN",
			},
			{
				id: "18",
				project: "EnergyPLUS Home 4.0",
				name: "TJU-TUDA",
				avatar: "tju-tuda",
				sch1: "Tongji University",
				con1: "CN",
				sch2: "Technical University of Darmstadt",
				con2: "DE",
			},
			{
				id: "19",
				project: "R.E.A.L House",
				name: "Solar Offspring",
				avatar: "solar-offspring",
				sch1: "Hunan University",
				con1: "CN",
			},
			{
				id: "20",
				project: "LONG-PLAN",
				name: "SCUT-POLITO",
				avatar: "scut-polito",
				sch1: "South China University of Technology",
				con1: "CN",
				sch2: "Politecnico di Torino",
				con2: "IT",
			},
		],

		juryPanel: [
			{
				name: "Brian LEE",
				avatar: "brian_lee",
				title: "FAIA, Design Partner of Skidmore, Owings & Merrill LLP",
				group: "Architecture",
				color: "primary",
			},
			{
				name: "MENG Jianmin",
				avatar: "jianmin_meng",
				title: "Academician, Chinese Academy of Engineering",
				group: "Architecture",
				color: "primary",
			},
			{
				name: "LIU Xiaodu",
				avatar: "xiaodu_liu",
				title: "Principal Architect and Co-founder, URBANUS Architecture & Design Inc.",
				group: "Architecture",
				color: "primary",
			},
			{
				name: "Bruce LINDSEY",
				avatar: "bruce_lindsey",
				title: "Dean, College of Architecture, Washington University in St. Louis",
				group: "Architecture",
				color: "primary",
			},
			{
				name: "Volker HARTKOPF",
				avatar: "volker_hartkopf",
				title: "Professor, Carnegie Mellon University",
				group: "Architecture",
				color: "primary",
			},
			{
				name: "SU Yunsheng",
				avatar: "yunsheng_su",
				title: "Director, Technical Development Department, Shanghai Tongji Urban Planning & Design Institute",
				group: "Market Appeal",
				color: "warning",
			},
			{
				name: "ZHAO Zhonggui",
				avatar: "zhonggui_zhao",
				title: "Partner, Zephyr (US) Architects P.C.",
				group: "Market Appeal",
				color: "warning",
			},
			{
				name: "XU Hailong",
				avatar: "hailong_xu",
				title: "Director, Green Horizon Program, IBM",
				group: "Market Appeal",
				color: "warning",
			},
			{
				name: "Luke LEUNG",
				avatar: "luke_leung",
				title: "Director of Sustainable Engineering, Skidmore, Owings & Merrill LLP",
				group: "Engineering",
				color: "success",
			},
			{
				name: "YU Zhen",
				avatar: "zhen_yu",
				title: "Director, High-Performance Building Research Center, China Academy of Building Research",
				group: "Engineering",
				color: "success",
			},
			{
				name: "Stephen LAU",
				avatar: "stephen_lau",
				title: "Deputy Head-Research, Director, ArchPhD Program, National University of Singapore",
				group: "Engineering",
				color: "success",
			},
			{
				name: "HE Wei",
				avatar: "wei_he",
				title: "Associate Professor, China Central Academy of Fine Arts",
				group: "Communications",
				color: "error",
			},
			{
				name: "LIU Heng",
				avatar: "heng_liu",
				title: "Founder, NODE; Associate Professor, School of Architecture, the Chinese University of Hong Kong",
				group: "Communications",
				color: "error",
			},
			{
				name: "WU Changhua",
				avatar: "changhua_wu",
				title: "Greater China Director and Chair, The Climate Group",
				group: "Communications",
				color: "error",
			},
			{
				name: "YE Qing",
				avatar: "qing_ye",
				title: "Director, Shenzhen Institute of Building Research",
				group: "Innovations",
				color: "secondary",
			},
			{
				name: "Joseph SIMON",
				avatar: "joseph_simon",
				title: "Competition Manager, Solar Decathlon",
				group: "Innovations",
				color: "secondary",
			},
			{
				name: "Martin BECHTHOLD",
				avatar: "martin_bechthold",
				title: "Director, Doctor of Design Program, Architectural Technology, Harvard Graduate School of Design",
				group: "Innovations",
				color: "secondary",
			},
		],

		headers: [
			{ text: "Team", value: "team", sortable: false },
			{
				text: "Architecture",
				value: "arch",
			},
			{
				text: "Market Appeal",
				value: "mark",
			},
			{
				text: "Engineering",
				value: "engi",
			},
			{
				text: "Communications",
				value: "comm",
			},
			{
				text: "Innovations",
				value: "inno",
			},
			{
				text: "Comfort Zone",
				value: "comf",
			},
			{
				text: "Appliance",
				value: "appl",
			},
			{
				text: "Home Life",
				value: "home",
			},
			{
				text: "Commuting",
				value: "cmut",
			},
			{
				text: "Energy",
				value: "engy",
			},
			{
				text: "Penalty",
				value: "pena",
			},
			{
				text: "Total",
				value: "total",
			},
		],

		scores: [
			{
				team: "B&R",
				arch: "74.00",
				mark: "83.70",
				engi: "76.00",
				comm: "64.90",
				inno: "70.00",
				comf: "55.62",
				appl: "67.93",
				home: "73.39",
				cmut: "99.38",
				engy: "9.29",
				pena: "-0.03",
				total: "674.18",
			},
			{
				team: "THU",
				arch: "93.00",
				mark: "95.10",
				engi: "95.00",
				comm: "96.90",
				inno: "90.00",
				comf: "93.93",
				appl: "99.70",
				home: "97.68",
				cmut: "100.00",
				engy: "91.09",
				pena: "-4.03",
				total: "948.37",
			},
			{
				team: "XJTU-WNEU-POLIMI",
				arch: "76.00",
				mark: "70.60",
				engi: "83.00",
				comm: "87.20",
				inno: "50.00",
				comf: "53.04",
				appl: "97.81",
				home: "84.44",
				cmut: "100.00",
				engy: "90.45",
				pena: "-10.00",
				total: "782.54",
			},
			{
				team: "YI",
				arch: "74.00",
				mark: "68.50",
				engi: "80.00",
				comm: "74.30",
				inno: "65.00",
				comf: "80.39",
				appl: "93.04",
				home: "98.88",
				cmut: "100.00",
				engy: "90.44",
				pena: "-5.00",
				total: "819.55",
			},
			{
				team: "CNBM-WIN",
				arch: "85.00",
				mark: "87.40",
				engi: "84.00",
				comm: "92.10",
				inno: "65.00",
				comf: "76.62",
				appl: "91.94",
				home: "88.73",
				cmut: "100.00",
				engy: "6.44",
				pena: "-8.00",
				total: "769.23",
			},
			{
				team: "SUES-XD",
				arch: "65.00",
				mark: "61.70",
				engi: "75.00",
				comm: "37.40",
				inno: "45.00",
				comf: "88.16",
				appl: "89.54",
				home: "98.50",
				cmut: "100.00",
				engy: "91.90",
				pena: "-19.00",
				total: "733.20",
			},
			{
				team: "Shunya",
				arch: "67.00",
				mark: "74.00",
				engi: "78.00",
				comm: "84.30",
				inno: "45.00",
				comf: "41.16",
				appl: "63.52",
				home: "62.52",
				cmut: "100.00",
				engy: "13.10",
				pena: "-2.00",
				total: "626.60",
			},
			{
				team: "Montreal",
				arch: "97.00",
				mark: "97.80",
				engi: "94.00",
				comm: "97.00",
				inno: "87.00",
				comf: "43.19",
				appl: "82.67",
				home: "79.60",
				cmut: "100.00",
				engy: "85.56",
				pena: "0.00",
				total: "863.82",
			},
			{
				team: "JIA+",
				arch: "87.00",
				mark: "90.30",
				engi: "93.00",
				comm: "94.00",
				inno: "82.00",
				comf: "85.75",
				appl: "96.66",
				home: "99.40",
				cmut: "100.00",
				engy: "90.77",
				pena: "-3.06",
				total: "915.82",
			},
			{
				team: "TUBSEU",
				arch: "95.00",
				mark: "85.60",
				engi: "92.30",
				comm: "90.40",
				inno: "85.00",
				comf: "88.77",
				appl: "97.26",
				home: "99.22",
				cmut: "100.00",
				engy: "87.25",
				pena: "-5.00",
				total: "915.80",
			},
			{
				team: "XAUAT",
				arch: "81.00",
				mark: "74.70",
				engi: "82.00",
				comm: "73.50",
				inno: "60.00",
				comf: "80.75",
				appl: "99.27",
				home: "98.58",
				cmut: "100.00",
				engy: "90.45",
				pena: "-7.00",
				total: "833.25",
			},
			{
				team: "SJTUIUC",
				arch: "88.00",
				mark: "85.50",
				engi: "85.30",
				comm: "82.60",
				inno: "75.00",
				comf: "84.29",
				appl: "88.58",
				home: "82.62",
				cmut: "100.00",
				engy: "91.88",
				pena: "-3.00",
				total: "860.77",
			},
			{
				team: "BJTU",
				arch: "89.00",
				mark: "79.30",
				engi: "87.00",
				comm: "88.30",
				inno: "82.00",
				comf: "78.88",
				appl: "99.05",
				home: "97.90",
				cmut: "100.00",
				engy: "100.00",
				pena: "-3.00",
				total: "898.43",
			},
			{
				team: "SIE",
				arch: "78.00",
				mark: "79.00",
				engi: "86.00",
				comm: "70.40",
				inno: "62.00",
				comf: "73.54",
				appl: "85.75",
				home: "96.21",
				cmut: "100.00",
				engy: "93.28",
				pena: "-4.50",
				total: "819.68",
			},
			{
				team: "TJU-TUDA",
				arch: "92.00",
				mark: "87.30",
				engi: "85.30",
				comm: "92.60",
				inno: "79.00",
				comf: "87.91",
				appl: "81.68",
				home: "89.17",
				cmut: "87.50",
				engy: "90.94",
				pena: "0.00",
				total: "873.40",
			},
			{
				team: "Solar Offspring",
				arch: "76.00",
				mark: "72.60",
				engi: "81.00",
				comm: "75.80",
				inno: "58.00",
				comf: "85.16",
				appl: "87.31",
				home: "88.02",
				cmut: "100.00",
				engy: "87.24",
				pena: "0.00",
				total: "811.13",
			},
			{
				team: "SCUT-POLITO",
				arch: "96.00",
				mark: "97.70",
				engi: "96.00",
				comm: "92.60",
				inno: "95.00",
				comf: "94.08",
				appl: "98.68",
				home: "99.23",
				cmut: "100.00",
				engy: "91.95",
				pena: "-1.51",
				total: "959.74",
			},
		],
	}),
};
</script>

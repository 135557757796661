<template>
	<section>
		<base-section class="px-12" style="background: transparent">
			<base-section-heading :title="$t('datong2013')">
				August 2 - 13, 2013
			</base-section-heading>

			<v-row align="center" justify="center">
				<v-col cols="6">
					<iframe
						src="//player.bilibili.com/player.html?aid="
						scrolling="no"
						border="0"
						frameborder="no"
						framespacing="0"
						allowfullscreen="true"
						width="100%"
						height="450"
					>
					</iframe>
				</v-col>
				<v-col cols="6">
					<p>
						Solar Decathlon China 2013 took place in Datong, Shanxi
						Province from August 2nd to 13th. The event was hosted
						by the China National Energy Administration and the U.S.
						Department of Energy, co-hosted by the Ministry of
						Finance and the Ministry of Housing and Urban-Rural
						Development, and organized by Peking University. It was
						the first time that Solar Decathlon was held in Asia.
					</p>
					<p>
						22 teams consisting of 35 schools from 13 countries
						participated. 20 teams were qualified for the final,
						including 19 competition teams and 1 exhibition team.
						Team UOW, Team SCUT, and Team Sweden were the top
						finishers. Team UOW claimed the title of Solar Decathlon China 2013 with
						a total score of 959.56. The event attracted around
						230,000 visitors and over 530,000 visits.
					</p>
				</v-col>
			</v-row>
		</base-section>

		<!-- Contests -->
		<base-section class="px-12" style="background: transparent">
			<base-section-heading :title="$t('contests')">
				Solar Decathlon China 2013 consists of 10 separately scored
				contests, and some contests contain one or more subcontests.
				Peruse Rules for details.
			</base-section-heading>

			<v-row>
				<v-col cols="12" class="d-flex justify-space-between">
					<v-card
						shaped
						style="width: 260px"
						v-for="(contest, i) in contests"
						:key="i"
						v-if="i < 5"
					>
						<v-card-text class="text-center">
							<v-avatar
								size="60"
								:color="contest.color + ' lighten-3'"
							>
								<v-icon large>{{ contest.icon }}</v-icon>
							</v-avatar>
							<h3 class="mt-3">{{ contest.title }}</h3>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12" class="d-flex justify-space-between">
					<v-card
						shaped
						style="width: 260px"
						v-for="(contest, i) in contests"
						:key="i"
						v-if="i > 4"
					>
						<v-card-text class="text-center">
							<v-avatar
								size="60"
								:color="contest.color + ' lighten-3'"
							>
								<v-icon large>{{ contest.icon }}</v-icon>
							</v-avatar>
							<h3 class="mt-3">{{ contest.title }}</h3>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</base-section>

		<!-- Finalist -->
		<base-section class="px-12" style="background: transparent">
			<base-section-heading :title="$t('finalist')">
				20 teams consisting of 33 schools from 11 countries were
				qualified for the final, including 19 competition teams and 1
				exhibition team.
			</base-section-heading>
			<v-row>
				<v-col cols="3" v-for="(team, i) in finalist" :key="i">
					<v-card outlined height="360px">
						<v-img
							class="white--text align-end"
							height="180px"
							:src="
								require(`@/assets/images/datong/rendering.png`)
							"
						>
							<v-card-title>{{ team.project }}</v-card-title>
						</v-img>

						<v-card-title class="d-flex justify-space-between">
							<v-avatar>
								<img
									:src="
										require(`@/assets/images/datong/logo.png`)
									"
								/>
							</v-avatar>
							<v-badge
								left
								:content="team.id"
								:color="team.exh ? 'warning' : 'primary'"
							>
								{{ team.name }}</v-badge
							>
						</v-card-title>
						<v-card-text
							:style="
								team.scr
									? 'height: 98px; overflow-y: scroll'
									: ''
							"
						>
							<div class="d-flex justify-space-between">
								<span class="text-truncate">{{
									team.sch1
								}}</span>
								<span class="primary--text">{{
									team.con1
								}}</span>
							</div>
							<div class="d-flex justify-space-between">
								<span class="text-truncate">{{
									team.sch2
								}}</span>
								<span class="primary--text">{{
									team.con2
								}}</span>
							</div>
							<div class="d-flex justify-space-between">
								<span class="text-truncate">{{
									team.sch3
								}}</span>
								<span class="primary--text">{{
									team.con3
								}}</span>
							</div>
							<div class="d-flex justify-space-between">
								<span class="text-truncate">{{
									team.sch4
								}}</span>
								<span class="primary--text">{{
									team.con4
								}}</span>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</base-section>

		<!-- Results -->
		<base-section class="px-12" style="background: transparent">
			<base-section-heading :title="$t('results')">
				The top finishers were Team UOW, Team SCUT, and Team Sweden.
				Team UOW claimed the title of Solar Decathlon China 2013 with a
				total score of 959.56.
			</base-section-heading>
			<v-data-table
				:headers="headers"
				:items="scores"
				:items-per-page="19"
				sort-by="total"
				sort-desc="true"
				hide-default-footer
				class="elevation-0"
			></v-data-table>
		</base-section>
	</section>
</template>

<script>
export default {
	data: () => ({
		contests: [
			{
				title: "Architecture",
				icon: "mdi-home",
				color: "blue",
			},
			{
				title: "Market Appeal",
				icon: "mdi-currency-usd",
				color: "lime",
			},
			{
				title: "Engineering",
				icon: "mdi-code-tags",
				color: "cyan",
			},
			{
				title: "Communications",
				icon: "mdi-share-variant",
				color: "green",
			},
			{
				title: "Solar Application",
				icon: "mdi-solar-power",
				color: "brown",
			},
			{
				title: "Comfort Zone",
				icon: "mdi-thermometer",
				color: "yellow",
			},
			{
				title: "Hot Water",
				icon: "mdi-water-boiler",
				color: "teal",
			},
			{
				title: "Appliances",
				icon: "mdi-fridge",
				color: "orange",
			},
			{
				title: "Home Entertainment",
				icon: "mdi-television-classic",
				color: "amber",
			},
			{
				title: "Energy Balance",
				icon: "mdi-flash",
				color: "red",
			},
		],

		finalist: [
			{
				id: "01",
				project: "Green Home",
				name: "Alfred and Guilin",
				avatar: "b&r",
				sch1: "Alfred University",
				con1: "US",
				sch2: "Alfred State College",
				con2: "US",
				sch3: "Guilin University of Technology",
				con3: "CN",
			},
			{
				id: "02",
				project: "Solatrium",
				name: "BE-MA-NY",
				avatar: "b&r",
				sch1: "Ghent University",
				con1: "BE",
				sch2: "Worcester Polytechnic Institute",
				con2: "US",
				sch3: "Polytechnic Institute of New York University",
				con3: "US",
			},
			{
				id: "03",
				project: "i-Yard",
				name: "BJTU",
				avatar: "b&r",
				sch1: "Beijing Jiaotong University",
				con1: "CN",
				sch2: "Bern University of Applied Sciences",
				con2: "CH",
			},
			{
				id: "04",
				project: "Genbu",
				name: "Green Sun",
				avatar: "b&r",
				sch1: "Inner Mongolia University of Technology",
				con1: "CN",
			},
			{
				id: "05",
				project: "SunBloc",
				name: "HelioMet",
				avatar: "b&r",
				sch1: "London Metropolitan University",
				con1: "UK",
				sch2: "Guangzhou Academy of Fine Arts",
				con2: "CN",
			},
			{
				id: "06",
				project: "Shareman",
				name: "IRAN-SUES",
				avatar: "b&r",
				sch1: "Abbaspour University of Technology",
				con1: "IR",
				sch2: "Shanghai University of Engineering Science",
				con2: "CN",
			},
			{
				id: "07",
				project: "I4E House",
				name: "Israel",
				avatar: "b&r",
				sch1: "Tel Aviv University",
				con1: "IL",
				sch2: "Shenkar College of Engineering and Design",
				con2: "IL",
				sch3: "Neri Bloomfield School of Design and Education",
				con3: "IL",
				sch4: "College of Management Academic Studies",
				con4: "IL",
			},
			{
				id: "08",
				project: "NEXUS House",
				name: "NJIT-Harbin",
				avatar: "b&r",
				sch1: "New Jersey Institute of Technology",
				con1: "US",
				sch2: "Harbin Institute of Technology",
				con2: "CN",
			},
			{
				id: "09",
				project: "Solar House",
				name: "NUS",
				avatar: "b&r",
				sch1: "National University of Singapore",
				con1: "SG",
			},
			{
				id: "10",
				project: "E-CONCAVE",
				name: "SCUT",
				avatar: "b&r",
				sch1: "South China University of Technology",
				con1: "CN",
			},
			{
				id: "11",
				project: "SOLARK",
				name: "SEU",
				avatar: "b&r",
				sch1: "Southeast University",
				con1: "CN",
			},
			{
				id: "12",
				project: "River Sunvelop",
				name: "SJTU",
				avatar: "b&r",
				sch1: "Shanghai Jiaotong University",
				con1: "CN",
			},
			{
				id: "13",
				project: "i-Magic Cube",
				name: "SJU",
				avatar: "b&r",
				sch1: "Shandong Jianzhu University",
				con1: "CN",
			},
			{
				id: "14",
				project: "Halo",
				name: "Sweden",
				avatar: "b&r",
				sch1: "Chalmers University of Technology",
				con1: "SE",
			},
			{
				id: "15",
				project: "O-House",
				name: "THU-FIU",
				avatar: "b&r",
				sch1: "Tsinghua University",
				con1: "CN",
				sch2: "Florida International University",
				con2: "US",
			},
			{
				id: "16",
				project: "Illawarra Flame",
				name: "UOW",
				avatar: "b&r",
				sch1: "University of Wollongong",
				con1: "AU",
			},
			{
				id: "17",
				project: "Eco Home",
				name: "Solar-Home-UTM",
				avatar: "b&r",
				sch1: "Universiti Teknologi Malaysia",
				con1: "MY",
			},
			{
				id: "18",
				project: "Qiju",
				name: "XAUAT",
				avatar: "b&r",
				sch1: "Xi'an University of Architecture and Technology",
				con1: "CN",
			},
			{
				id: "19",
				project: "Sunny Inside",
				name: "Xiamen Univ.",
				avatar: "b&r",
				sch1: "Xiamen University",
				con1: "CN",
			},
			{
				id: "20",
				project: "Etho",
				name: "PKU-UIUC",
				avatar: "b&r",
				sch1: "Peking University",
				con1: "CN",
				sch2: "University of Illinois at Urbana-Champaign",
				con2: "US",
				exh: true,
			},
		],

		headers: [
			{ text: "Team", value: "team", sortable: false },
			{
				text: "Architecture",
				value: "arch",
			},
			{
				text: "Market Appeal",
				value: "mark",
			},
			{
				text: "Engineering",
				value: "engi",
			},
			{
				text: "Communications",
				value: "comm",
			},
			{
				text: "Solar Application",
				value: "sola",
			},
			{
				text: "Comfort Zone",
				value: "comf",
			},
			{
				text: "Hot Water",
				value: "hotw",
			},
			{
				text: "Appliances",
				value: "appl",
			},
			{
				text: "Home Entertainment",
				value: "home",
			},
			{
				text: "Energy Balance",
				value: "engy",
			},
			{
				text: "Total",
				value: "total",
			},
		],

		scores: [
			{
				team: "Alfred and Guilin",
				arch: "48.00",
				mark: "82.00",
				engi: "66.00",
				comm: "78.00",
				sola: "73.00",
				comf: "74.70",
				hotw: "0.00",
				appl: "75.68",
				home: "98.64",
				engy: "100.00",
				total: "696.02",
			},
			{
				team: "BE-MA-NY",
				arch: "81.00",
				mark: "89.00",
				engi: "75.00",
				comm: "90.00",
				sola: "81.00",
				comf: "93.54",
				hotw: "100.00",
				appl: "99.35",
				home: "88.17",
				engy: "100.00",
				total: "897.06",
			},
			{
				team: "BJTU",
				arch: "74.00",
				mark: "80.00",
				engi: "70.00",
				comm: "65.00",
				sola: "67.00",
				comf: "76.04",
				hotw: "85.66",
				appl: "95.23",
				home: "97.68",
				engy: "100.00",
				total: "810.57",
			},
			{
				team: "Green Sun",
				arch: "45.00",
				mark: "92.00",
				engi: "72.00",
				comm: "71.00",
				sola: "72.00",
				comf: "92.33",
				hotw: "100.00",
				appl: "99.12",
				home: "98.80",
				engy: "100.00",
				total: "842.25",
			},
			{
				team: "HelioMet",
				arch: "83.00",
				mark: "75.00",
				engi: "56.00",
				comm: "61.00",
				sola: "71.00",
				comf: "38.29",
				hotw: "81.25",
				appl: "36.03",
				home: "44.69",
				engy: "100.00",
				total: "646.26",
			},
			{
				team: "IRAN-SUES",
				arch: "60.00",
				mark: "68.00",
				engi: "72.00",
				comm: "68.00",
				sola: "66.00",
				comf: "75.74",
				hotw: "93.75",
				appl: "76.89",
				home: "82.29",
				engy: "100.00",
				total: "762.67",
			},
			{
				team: "Israel",
				arch: "95.00",
				mark: "96.00",
				engi: "87.00",
				comm: "88.00",
				sola: "83.00",
				comf: "98.74",
				hotw: "100.00",
				appl: "98.04",
				home: "98.55",
				engy: "100.00",
				total: "944.33",
			},
			{
				team: "NJIT-Harbin",
				arch: "68.00",
				mark: "97.00",
				engi: "82.00",
				comm: "87.00",
				sola: "76.00",
				comf: "98.28",
				hotw: "93.30",
				appl: "94.62",
				home: "97.94",
				engy: "100.00",
				total: "894.14",
			},
			{
				team: "NUS",
				arch: "73.00",
				mark: "96.00",
				engi: "88.00",
				comm: "83.00",
				sola: "82.00",
				comf: "92.65",
				hotw: "100.00",
				appl: "89.99",
				home: "92.68",
				engy: "100.00",
				total: "897.31",
			},
			{
				team: "SCUT",
				arch: "94.00",
				mark: "98.00",
				engi: "93.00",
				comm: "91.00",
				sola: "80.00",
				comf: "96.93",
				hotw: "100.00",
				appl: "99.75",
				home: "99.17",
				engy: "100.00",
				total: "951.85",
			},
			{
				team: "SEU",
				arch: "58.00",
				mark: "94.00",
				engi: "84.00",
				comm: "69.00",
				sola: "78.00",
				comf: "96.53",
				hotw: "100.00",
				appl: "96.28",
				home: "98.19",
				engy: "100.00",
				total: "874.00",
			},
			{
				team: "SJTU",
				arch: "46.00",
				mark: "95.00",
				engi: "84.00",
				comm: "69.00",
				sola: "79.00",
				comf: "93.77",
				hotw: "98.93",
				appl: "87.19",
				home: "97.76",
				engy: "100.00",
				total: "850.66",
			},
			{
				team: "SJU",
				arch: "42.00",
				mark: "83.00",
				engi: "78.00",
				comm: "72.00",
				sola: "74.00",
				comf: "97.05",
				hotw: "100.00",
				appl: "85.00",
				home: "98.47",
				engy: "100.00",
				total: "829.52",
			},
			{
				team: "Sweden",
				arch: "86.00",
				mark: "96.00",
				engi: "92.00",
				comm: "95.00",
				sola: "85.00",
				comf: "97.14",
				hotw: "100.00",
				appl: "98.99",
				home: "98.21",
				engy: "100.00",
				total: "948.35",
			},
			{
				team: "THU-FIU",
				arch: "92.00",
				mark: "95.00",
				engi: "89.00",
				comm: "81.00",
				sola: "84.00",
				comf: "99.10",
				hotw: "100.00",
				appl: "97.31",
				home: "99.02",
				engy: "100.00",
				total: "936.43",
			},
			{
				team: "UOW",
				arch: "96.00",
				mark: "97.00",
				engi: "95.00",
				comm: "94.00",
				sola: "86.00",
				comf: "97.91",
				hotw: "100.00",
				appl: "99.58",
				home: "94.07",
				engy: "100.00",
				total: "959.56",
			},
			{
				team: "Solar-Home-UTM",
				arch: "33.00",
				mark: "78.00",
				engi: "72.00",
				comm: "67.00",
				sola: "77.00",
				comf: "91.16",
				hotw: "81.25",
				appl: "92.27",
				home: "87.70",
				engy: "100.00",
				total: "779.38",
			},
			{
				team: "XAUAT",
				arch: "64.00",
				mark: "83.00",
				engi: "85.00",
				comm: "70.00",
				sola: "64.00",
				comf: "94.07",
				hotw: "100.00",
				appl: "98.01",
				home: "95.91",
				engy: "100.00",
				total: "854.00",
			},
			{
				team: "Xiamen Univ.",
				arch: "87.00",
				mark: "94.00",
				engi: "90.00",
				comm: "85.00",
				sola: "75.00",
				comf: "96.91",
				hotw: "100.00",
				appl: "98.71",
				home: "95.74",
				engy: "100.00",
				total: "922.35",
			},
		],
	}),
};
</script>
<template>
	<v-container>
		<v-row>
			<v-col cols="2">
				<v-sheet
					class="mx-auto"
					rounded="lg"
					style="position: sticky; top: 76px"
				>
					<v-list color="transparent">
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title
									class="
										text-h6
										font-weight-light
										text-uppercase
									"
								>
									{{ $t("archives") }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-divider class="mt-1"></v-divider>

						<v-list-item-group color="primary">
							<v-list-item
								:class="
									pageShow == 2022
										? 'v-item--active v-list-item--active'
										: ''
								"
								class="text-button"
								to="/archives/2022"
								@click="pageShow = 2022"
							>
								{{ $t("zhangjiakou2022") }}
							</v-list-item>
							<v-list-item
								:class="
									pageShow == 2018
										? 'v-item--active v-list-item--active'
										: ''
								"
								class="text-button"
								to="/archives/2018"
								@click="pageShow = 2018"
							>
								{{ $t("dezhou2018") }}
							</v-list-item>
							<v-list-item
								:class="
									pageShow == 2013
										? 'v-item--active v-list-item--active'
										: ''
								"
								class="text-button"
								to="/archives/2013"
								@click="pageShow = 2013"
							>
								{{ $t("datong2013") }}
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-sheet>
			</v-col>

			<v-col cols="10">
				<v-sheet min-height="70vh" rounded="lg">
					<zhangjiakou-2022
						v-if="pageShow == 2022"
					></zhangjiakou-2022>
					<dezhou-2018 v-else-if="pageShow == 2018"></dezhou-2018>
					<datong-2013 v-else-if="pageShow == 2013"></datong-2013>
				</v-sheet>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Zhangjiakou2022 from "@/views/archives/sections/Zhangjiakou2022.vue";
import Dezhou2018 from "@/views/archives/sections/Dezhou2018.vue";
import Datong2013 from "@/views/archives/sections/Datong2013.vue";
import router from "@/router/index.js";

export default {
	name: "Archives",

	components: {
		Zhangjiakou2022,
		Dezhou2018,
		Datong2013,
	},
	data() {
		return {
			pageShow: 2022,
		};
	},
	mounted() {
		const pageShow = router.currentRoute.params.pageShow;
		this.pageShow = parseInt(pageShow || 2022);
	},
};
</script>
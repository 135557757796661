<template>
	<section>
		<base-section class="px-12" style="background: transparent">
			<base-section-heading :title="$t('zhangjiakou2022')">
				August 8 - 14, 2022
			</base-section-heading>
			<v-row align="center" justify="center">
				<v-col cols="6"
					><iframe
						src="//player.bilibili.com/player.html?aid=805596946&bvid=BV1B34y1S7VJ&cid=411392953&page=1"
						scrolling="no"
						border="0"
						frameborder="no"
						framespacing="0"
						allowfullscreen="true"
						width="100%"
						height="450"
					>
					</iframe
				></v-col>

				<v-col cols="6">
					<p>
						In cooperation with the city of Zhangjiakou, Hebei
						Province, the third Solar Decathlon China event was
						originally scheduled in the fall of 2021. Due to the
						global epidemic of Covid-19, the competition was
						postponed and the final eventually took place in August
						2022.
					</p>
					<p>
						After two and a half years' preparation, 15 teams
						consisting of 29 schools from 10 countries, in joint
						with over 180 enterprises and organizations, were
						qualified for the final. The 15 entry prototypes were
						assembled in the competition village in September 2021,
						and refined in July 2022. The top finishers of the
						competition were Team Tianjin U+, Team Solar Ark, and
						Team THU.
					</p>
					<p>
						It was the first time that Solar Decathlon challenges
						teams to operate houses in off-grid mode for over 48
						hours, to demonstrate the posibility and capability of a
						zero-energy community. After the competition, all the 15
						entries will be kept on site to serve the long-term
						research and educational activities.
					</p>
					<p>
						Zhangjiakou, located 190 kilometers (120 mi) northwest
						of Beijing, is the joint host city of Beijing Winter
						Olympic 2022, and is the first and only existing one
						National Renewable Energy Demonstrative Zone approved by
						the State Council of China. Temperature in Zhangjiakou
						typically varies from lows of -15 °C (5 °F) in winter to
						highs of 29 °C (84 °F) in summer.
					</p>
				</v-col>
			</v-row>
		</base-section>

		<!-- Challenges -->
		<base-section class="px-12" style="background: transparent">
			<base-section-heading :title="$t('challenges')">
				Solar Decathlon China 2022 encourages competition prototypes to
				meet a triple challenge in the context of the host city.
			</base-section-heading>
			<v-row>
				<v-col v-for="(challenge, i) in challenges" :key="i" cols="4">
					<base-avatar-card
						v-bind="challenge"
						align="left"
						horizontal
					>
						{{ challenge.text }}
					</base-avatar-card>
				</v-col>
			</v-row>
		</base-section>

		<!-- Contests -->
		<base-section class="px-12" style="background: transparent">
			<base-section-heading :title="$t('contests')">
				Solar Decathlon China 2022 consists of 10 separately scored
				contests, and some contests contain one or more subcontests.
				Peruse Rules for details.
			</base-section-heading>

			<v-row>
				<v-col cols="12" class="d-flex justify-space-between">
					<v-card
						shaped
						style="width: 260px"
						v-for="(contest, i) in contests"
						:key="i"
						v-if="i < 5"
					>
						<v-card-text class="text-center">
							<v-avatar
								size="60"
								:color="contest.color + ' lighten-3'"
							>
								<v-icon large>{{ contest.icon }}</v-icon>
							</v-avatar>
							<h3 class="mt-3">{{ contest.title }}</h3>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12" class="d-flex justify-space-between">
					<v-card
						shaped
						style="width: 260px"
						v-for="(contest, i) in contests"
						:key="i"
						v-if="i > 4"
					>
						<v-card-text class="text-center">
							<v-avatar
								size="60"
								:color="contest.color + ' lighten-3'"
							>
								<v-icon large>{{ contest.icon }}</v-icon>
							</v-avatar>
							<h3 class="mt-3">{{ contest.title }}</h3>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</base-section>

		<!-- Finalist -->
		<base-section class="px-12" style="background: transparent">
			<base-section-heading :title="$t('finalist')">
				The finalist of Solar Decathlon China 2022 was announced on 10
				Nov 2020. 15 teams consisting of 29 schools from 10 countries
				are qualified for the final.
			</base-section-heading>
			<v-row>
				<v-col cols="3" v-for="(team, i) in finalist" :key="i">
					<v-card outlined height="360px">
						<v-img
							class="white--text align-end"
							height="180px"
							:src="
								require(`@/assets/images/zhangjiakou/renderings/${team.avatar}.jpg`)
							"
						>
							<v-card-title>{{ team.project }}</v-card-title>
						</v-img>

						<v-card-title class="d-flex justify-space-between">
							<v-avatar>
								<img
									:src="
										require(`@/assets/images/zhangjiakou/logos/${team.avatar}.png`)
									"
								/>
							</v-avatar>
							<v-badge left :content="team.id">
								{{ team.name }}</v-badge
							>
						</v-card-title>
						<v-card-text>
							<div class="d-flex justify-space-between">
								<span class="text-truncate">{{
									team.sch1
								}}</span>
								<span class="primary--text">{{
									team.con1
								}}</span>
							</div>
							<div class="d-flex justify-space-between">
								<span class="text-truncate">{{
									team.sch2
								}}</span>
								<span class="primary--text">{{
									team.con2
								}}</span>
							</div>
							<div class="d-flex justify-space-between">
								<span class="text-truncate">{{
									team.sch3
								}}</span>
								<span class="primary--text">{{
									team.con3
								}}</span>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</base-section>

		<!-- Results -->
		<base-section class="px-12" style="background: transparent">
			<base-section-heading :title="$t('results')">
				Team Tianjin U+ claimed the title of Solar Decathlon China 2022
				with a total score of 968.49. Team Solar Ark finished in second
				place, followed by Team THU in third place.
			</base-section-heading>
			<v-data-table
				:headers="headers"
				:items="scores"
				:items-per-page="15"
				sort-by="total"
				sort-desc="true"
				hide-default-footer
				class="elevation-0"
			></v-data-table>
		</base-section>

		<!-- Winners -->
		<base-section style="background: transparent">
			<base-section-heading :title="$t('winners')">
				Team Tianjin U+ claimed the title of Solar Decathlon China 2022. Team Solar Ark finished in second
				place, followed by Team THU in third place.
			</base-section-heading>

			<v-carousel cycle show-arrows-on-hover height="600">
				<v-carousel-item
					v-for="i in 10"
					:key="i"
					:src="
						require(`@/assets/images/zhangjiakou/winners/sdc2022_${i}.png`)
					"
				></v-carousel-item>
			</v-carousel>
		</base-section>

		<!-- Acknowledgement -->
		<base-section class="px-12" style="background: transparent">
			<base-section-heading :title="$t('acknowledgement')">
			</base-section-heading>
		</base-section>
	</section>
</template>

<script>
export default {
	data: () => ({
		challenges: [
			{
				title: "Challenge 1 / SUSTAINABLE DEVELOPMENT",
				icon: "mdi-leaf-circle",
				text: "Sustainability is the core feature of all designs, technologies, products, and systems. All efforts we are making through cooperation crossing disciplines, fields, and countries are for a more sustainable future of all human beings. Solar Decathlon China 2022 calls for innovative young people to make contributions to the achievement of the UN Sustainability Development Goals through integrated solutions that can respond to people’s daily realities.",
				color: "warning",
				dark: true,
			},
			{
				title: "CHALLENGE 2 / SMART CONNECTION",
				icon: "mdi-share-variant-outline",
				text: "Buildings of the future provide not just physical shelters, but seek an information infrastructure to make a brilliant world possible. Solar Decathlon China 2022 challenges teams to look beyond traditional building services, and to deliver houses that are intelligently connected and responsive to power grid, automation systems, home appliances, and interact with occupants to empower them with new levels of visibility and actionable information.",
				color: "primary",
				dark: true,
			},
			{
				title: "CHALLENGE 3 / HUMAN HEALTH",
				icon: "mdi-hospital-box",
				text: "On average, we spend about 90 percent of our time indoors, where pollutant levels are often higher than those outside. Solar Decathlon China 2022 challenges participating teams to deliver a healthy house for human inhabitants, creating non-toxic places of living and working that reduce or eliminate sick building syndrome and building-related illness. Meanwhile, the competition promotes a new healthy lifestyle that benefits to occupants’ mental development and daily enjoyment.",
				color: "success",
				dark: true,
			},
		],
		contests: [
			{
				title: "Architecture",
				icon: "mdi-home",
				color: "blue",
			},
			{
				title: "Engineering & Construction",
				icon: "mdi-code-tags",
				color: "cyan",
			},
			{
				title: "Energy",
				icon: "mdi-flash",
				color: "red",
			},
			{
				title: "Communications",
				icon: "mdi-share-variant",
				color: "green",
			},
			{
				title: "Market Potential",
				icon: "mdi-currency-usd",
				color: "lime",
			},
			{
				title: "Indoor Environment",
				icon: "mdi-thermometer",
				color: "yellow",
			},
			{
				title: "Renewable Heating & Cooling",
				icon: "mdi-fire",
				color: "amber",
			},
			{
				title: "Home Life",
				icon: "mdi-television-classic",
				color: "orange",
			},
			{
				title: "Interactive Experience",
				icon: "mdi-account-switch-outline",
				color: "teal",
			},
			{
				title: "Energy Self-Sufficiency",
				icon: "mdi-battery-charging",
				color: "brown",
			},
		],

		finalist: [
			{
				id: "01",
				project: "Y-Project",
				name: "Y-Team",
				avatar: "y-team",
				sch1: "Xi'an Jiaotong-Liverpool University",
				con1: "CN",
				sch2: "Zhejiang University/University of Illinois at Urbana-Champaign Institute",
				con2: "CN",
				sch3: "Thomas Jefferson University",
				con3: "US",
			},
			{
				id: "02",
				project: "24·35 Housing Home",
				name: "DUT and Associates",
				avatar: "dut-associates",
				sch1: "Dalian University of Technology",
				con1: "CN",
			},
			{
				id: "03",
				project: "Modular Sustainable Cube",
				name: "HIT+",
				avatar: "hit+",
				sch1: "Harbin Institute of Technology",
				con1: "CN",
			},
			{
				id: "04",
				project: "Aurora",
				name: "DTU-SUDA",
				avatar: "dtu-suda",
				sch1: "Technical University of Denmark",
				con1: "DK",
				sch2: "Soochow University",
				con2: "CN",
			},
			{
				id: "05",
				project: "The Steppe Ark",
				name: "THU",
				avatar: "thu",
				sch1: "Tsinghua University",
				con1: "CN",
			},
			{
				id: "06",
				project: "HUI House",
				name: "HUI",
				avatar: "hui",
				sch1: "Hefei University of Technology",
				con1: "CN",
				sch2: "University of Lille",
				con2: "FR",
			},
			{
				id: "07",
				project: "Pixel House",
				name: "SRF",
				avatar: "srf",
				sch1: "Shenzhen University",
				con1: "CN",
				sch2: "RMIT University",
				con2: "AU",
			},
			{
				id: "08",
				project: "T&A House",
				name: "CUMT&AGH&HSP",
				avatar: "cumt-agh-hsp",
				sch1: "China University of Mining and Technology",
				con1: "CN",
				sch2: "AGH University of Science and Technology",
				con2: "PL",
			},
			{
				id: "09",
				project: "R-CELLS",
				name: "Tianjin U+",
				avatar: "tianjin-u+",
				sch1: "Tianjin University",
				con1: "CN",
				sch2: "The Oslo School of Architecture and Design",
				con2: "PL",
				sch3: "Tianjin Chengjian University",
				con3: "CN",
			},
			{
				id: "10",
				project: "SMART",
				name: "XJTU+",
				avatar: "xjtu+",
				sch1: "Xi’an Jiaotong University",
				con1: "CN",
			},
			{
				id: "11",
				project: "Pitched House",
				name: "CCMH",
				avatar: "ccmh",
				sch1: "Chongqing University",
				con1: "CN",
			},
			{
				id: "12",
				project: "BBBC",
				name: "BJTU+",
				avatar: "bjtu+",
				sch1: "Beijing Jiaotong University",
				con1: "CN",
				sch2: "Loughborough University",
				con2: "UK",
			},
			{
				id: "13",
				project: "Solar Ark 3.0",
				name: "Solar Ark",
				avatar: "solar-ark",
				sch1: "Southeast University",
				con1: "CN",
				sch2: "Swiss Federal Institute of Technology Zurich",
				con2: "CH",
				sch3: "Sanming University",
				con3: "CN",
			},
			{
				id: "14",
				project: "Natural Courtyard",
				name: "Hope Land",
				avatar: "hope-land",
				sch1: "Zhejiang Normal University",
				con1: "CN",
				sch2: "Shenyang Jianzhu University",
				con2: "CN",
				sch3: "Chemnitz University of Technology",
				con3: "DE",
			},
			{
				id: "15",
				project: "Qiju 3.0",
				name: "Qiju 3.0",
				avatar: "qiju-3",
				sch1: "Xi’an University of Architecture and Technology",
				con1: "CN",
				sch2: "Southwest Minzu University",
				con2: "CN",
			},
		],

		headers: [
			{ text: "Team", value: "team", sortable: false },
			{
				text: "Architecture",
				value: "arch",
			},
			{
				text: "Engineering & Construction",
				value: "engi",
			},
			{
				text: "Energy",
				value: "ener",
			},
			{
				text: "Communications",
				value: "comm",
			},
			{
				text: "Market Potential",
				value: "mark",
			},
			{
				text: "Indoor Environment",
				value: "indo",
			},
			{
				text: "Renewable Heating & Cooling",
				value: "rene",
			},
			{
				text: "Home Life",
				value: "home",
			},
			{
				text: "Interactive Experience",
				value: "inte",
			},
			{
				text: "Energy Self-Sufficiency",
				value: "self",
			},
			{
				text: "Penalty / Bonus",
				value: "pena",
			},
			{
				text: "Total",
				value: "total",
			},
		],

		scores: [
			{
				team: "BJTU+",
				arch: "86.75",
				engi: "88.50",
				ener: "73.00",
				comm: "91.00",
				mark: "86.75",
				indo: "98.79",
				rene: "98.24",
				home: "99.99",
				inte: "95.11",
				self: "100.00",
				pena: "6.00",
				total: "924.12",
			},
			{
				team: "CCMH",
				arch: "86.50",
				engi: "84.00",
				ener: "77.00",
				comm: "84.00",
				mark: "86.00",
				indo: "99.77",
				rene: "97.35",
				home: "98.17",
				inte: "85.30",
				self: "98.96",
				pena: "3.00",
				total: "900.04",
			},
			{
				team: "CUMT&AGH&HSP",
				arch: "85.25",
				engi: "87.25",
				ener: "80.00",
				comm: "87.00",
				mark: "89.75",
				indo: "91.02",
				rene: "99.44",
				home: "95.30",
				inte: "65.15",
				self: "95.05",
				pena: "-1.00",
				total: "874.22",
			},
			{
				team: "DTU-SUDA",
				arch: "74.25",
				engi: "82.25",
				ener: "86.00",
				comm: "80.00",
				mark: "89.25",
				indo: "95.11",
				rene: "69.11",
				home: "90.21",
				inte: "43.79",
				self: "76.22",
				pena: "-1.00",
				total: "785.18",
			},
			{
				team: "DUT and Associates",
				arch: "89.00",
				engi: "79.00",
				ener: "73.00",
				comm: "77.00",
				mark: "81.75",
				indo: "93.41",
				rene: "91.28",
				home: "92.65",
				inte: "44.32",
				self: "71.35",
				pena: "-1.00",
				total: "791.76",
			},
			{
				team: "HIT+",
				arch: "85.25",
				engi: "84.00",
				ener: "82.00",
				comm: "75.00",
				mark: "83.50",
				indo: "94.70",
				rene: "95.36",
				home: "97.27",
				inte: "86.98",
				self: "95.83",
				pena: "-3.00",
				total: "876.89",
			},
			{
				team: "Hope Land",
				arch: "72.00",
				engi: "75.75",
				ener: "76.00",
				comm: "76.00",
				mark: "75.50",
				indo: "84.92",
				rene: "67.76",
				home: "84.43",
				inte: "71.00",
				self: "67.97",
				pena: "-3.00",
				total: "748.33",
			},
			{
				team: "HUI",
				arch: "88.00",
				engi: "77.25",
				ener: "90.00",
				comm: "85.00",
				mark: "83.50",
				indo: "92.36",
				rene: "89.77",
				home: "98.95",
				inte: "45.86",
				self: "94.79",
				pena: "0.00",
				total: "845.48",
			},
			{
				team: "Qiju 3.0",
				arch: "92.00",
				engi: "85.50",
				ener: "80.00",
				comm: "87.00",
				mark: "81.25",
				indo: "96.73",
				rene: "84.36",
				home: "98.14",
				inte: "66.87",
				self: "94.53",
				pena: "5.00",
				total: "871.38",
			},
			{
				team: "Solar Ark",
				arch: "95.50",
				engi: "91.50",
				ener: "88.00",
				comm: "88.00",
				mark: "94.50",
				indo: "100.00",
				rene: "99.83",
				home: "99.97",
				inte: "89.28",
				self: "100.00",
				pena: "-1.00",
				total: "945.58",
			},
			{
				team: "SRF",
				arch: "90.00",
				engi: "90.00",
				ener: "78.00",
				comm: "89.00",
				mark: "88.50",
				indo: "99.99",
				rene: "99.74",
				home: "99.68",
				inte: "85.09",
				self: "99.22",
				pena: "4.00",
				total: "923.22",
			},
			{
				team: "THU",
				arch: "90.25",
				engi: "93.50",
				ener: "88.00",
				comm: "90.00",
				mark: "87.75",
				indo: "99.46",
				rene: "98.33",
				home: "98.84",
				inte: "84.06",
				self: "98.96",
				pena: "4.00",
				total: "933.15",
			},
			{
				team: "Tianjin U+",
				arch: "95.50",
				engi: "95.00",
				ener: "92.00",
				comm: "92.00",
				mark: "91.00",
				indo: "100.00",
				rene: "100.00",
				home: "99.13",
				inte: "96.86",
				self: "100.00",
				pena: "7.00",
				total: "968.49",
			},
			{
				team: "XJTU+",
				arch: "82.00",
				engi: "75.75",
				ener: "84.00",
				comm: "83.00",
				mark: "88.00",
				indo: "88.48",
				rene: "99.97",
				home: "96.86",
				inte: "68.96",
				self: "97.92",
				pena: "0.00",
				total: "864.94",
			},
			{
				team: "Y-Team",
				arch: "73.75",
				engi: "79.00",
				ener: "83.00",
				comm: "81.00",
				mark: "83.75",
				indo: "91.37",
				rene: "78.68",
				home: "78.85",
				inte: "68.17",
				self: "30.73",
				pena: "-5.00",
				total: "743.31",
			},
		],
	}),
};
</script>
